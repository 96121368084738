import { Footer } from '../../components/Footer/Footer';
import PageHeader from '../../components/PageHeader';
import { FaWhatsapp, FaInfoCircle } from 'react-icons/fa';

import './styles.scss';

export function VacationInfoPage(){
   
       
    return (
        <div id="page-vacation" className="animator">
            <PageHeader title="Férias" />
            <main>
                <section>
                    <h1 className="title">Saiba mais sobre nosso período de férias!</h1>
                    <div>
                        <div className="days-of-vacation">
                            <h3>
                                Esses são os dias que estaremos em férias coletivas:
                            </h3>
                            <div>
                                <div className="calendar dezember">
                                    <h4>Dezembro - 2024</h4>
                                    <div className='WeekDayFlag'>D</div>
                                    <div>S</div>
                                    <div>T</div>
                                    <div>Q</div>
                                    <div>Q</div>
                                    <div>S</div>
                                    <div>S</div>
                                    <div className="d1">1</div>
                                    <div className="d2">2</div>
                                    <div className="d3">2</div>
                                    <div className="d4">4</div>
                                    <div className="d5">5</div>
                                    <div className="d6">6</div>
                                    <div className="d7">7</div>
                                    <div className="d8">8</div>
                                    <div className="d9">9</div>
                                    <div className="d10">10</div>
                                    <div className="d11">11</div>
                                    <div className="d12">12</div>
                                    <div className="d13">13</div>
                                    <div className="d14">14</div>
                                    <div className="d15">15</div>
                                    <div className="d16">16</div>
                                    <div className="d17">17</div>
                                    <div className="d18">18</div>
                                    <div className="d19">19</div>
                                    <div className="d20">20</div>
                                    <div className="d21">21</div>
                                    <div className="d22">22</div>
                                    <div className="d23">23</div>
                                    <div className="d24">24</div>
                                    <div className="d25">25</div>
                                    <div className="d26">26</div>
                                    <div className="d27">27</div>
                                    <div className="d28">28</div>
                                    <div className="d29">29</div>
                                    <div className="d30">30</div>
                                    <div className="d31">31</div>
                                    <div className="dayShadow">1</div>
                                    <div className="dayShadow">2</div>
                                    <div className="dayShadow">3</div>
                                    <div className="dayShadow">4</div>
                                </div>

                                <div className="calendar january">
                                    <h4>Janeiro - 2025</h4>
                                    <div>D</div>
                                    <div>S</div>
                                    <div>T</div>
                                    <div>Q</div>
                                    <div>Q</div>
                                    <div>S</div>
                                    <div>S</div>
                                    <div className="dayShadow">29</div>
                                    <div className="dayShadow">30</div>
                                    <div className="dayShadow">31</div>
                                    <div className="d1">1</div>
                                    <div className="d2">2</div>
                                    <div className="d3">2</div>
                                    <div className="d4">4</div>
                                    <div className="d5">5</div>
                                    <div className="d6">6</div>
                                    <div className="d7">7</div>
                                    <div className="d8">8</div>
                                    <div className="d9">9</div>
                                    <div className="d10">10</div>
                                    <div className="d11">11</div>
                                    <div className="d12">12</div>
                                    <div className="d13">13</div>
                                    <div className="d14">14</div>
                                    <div className="d15">15</div>
                                    <div className="d16">16</div>
                                    <div className="d17">17</div>
                                    <div className="d18">18</div>
                                    <div className="d19">19</div>
                                    <div className="d20">20</div>
                                    <div className="d21">21</div>
                                    <div className="d22">22</div>
                                    <div className="d23">23</div>
                                    <div className="d24">24</div>
                                    <div className="d25">25</div>
                                    <div className="d26">26</div>
                                    <div className="d27">27</div>
                                    <div className="d28">28</div>
                                    <div className="d29">29</div>
                                    <div className="d30">30</div>
                                    <div className="d31">31</div>
                                    <div className="dayShadow">1</div>
                                </div>
                            </div>
                        </div>
                        <div className="contact-info">
                            <div className="message">
                                <div><FaInfoCircle /></div>
                                <div>
                                    Em 2024 trabalhamos muito para ajudar nossos clientes a atingir o sucesso das suas empresas.<br />
                                    Agora precisamos descansar um pouco, renovar as forças para iniciar o ano de 2025!<br />
                                    Mas não deixaremos você sem atendimento, para assuntos inadiáveis entre em contato exclusivamente pelo Whatsapp abaixo:
                                </div>
                            </div>
                            <div className="whatsapp">
                                <FaWhatsapp />
                                <div>
                                    whatsapp / Celular
                                    <strong>+55 (48) 99810-8597</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}
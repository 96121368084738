import { Footer } from '../../components/Footer/Footer';
import PageHeader from '../../components/PageHeader';
import { FaWhatsapp, FaEnvelope, FaClock, FaInfoCircle, FaInstagram } from 'react-icons/fa';
import { DayClosed } from '../../components/DayClosed/DayClosed';

import qrImg from '../../assets/images/QR.png';

import './styles.scss';

export function ContactPage(){
    return (
        <div id="page-contact" className="animator">
            <PageHeader title="Contato" />
            <main>
                <section>
                    <h1 className="title">Fale conosco, será um prazer lhe atender!</h1>
                    <div>
                        <div className="qr-code">
                            <span>
                                Escaneio o QR Code com o seu celular
                            </span>
                            <a target="_blanc" href="https://wa.me/5548998108597"><img src={qrImg} alt="QR Code" /></a>
                            <span>
                                e converse conosco pelo <strong>Whatsapp</strong>!!
                            </span>
                        </div>
                        <div className="contact-info">
                            <div className="whatsapp">
                                <FaWhatsapp />
                                <div>
                                    whatsapp / Celular
                                    <strong>+55 (48) 99810-8597</strong>
                                </div>
                            </div>
                            <div className="mail">
                                <FaEnvelope />
                                <div>
                                    Envie-nos um e-mail
                                    <strong>contato@vcrccontabilidade.com.br</strong>
                                </div>
                            </div>
                            <div className="address">
                                <FaClock />
                                <div>
                                    Horário de atendimento
                                    <strong>Seg - Sex: 08:30 - 12:00, 13:30 - 18:00</strong>
                                    <strong>Sáb - Dom: Fechado  </strong>
                                    <DayClosed />
                                </div>
                            </div>
                            <div className="whatsapp">
                                <FaInstagram />
                                <div>
                                    Instagram
                                    <strong>@vcrc_contabilidade</strong>
                                </div>
                            </div>
                            <div className="message">
                                <div><FaInfoCircle /></div>
                                <div>
                                    Nós atendemos apenas com agendamento.<br />
                                    Marque seu horário de atendimento através do nosso Whatsapp ou e-mail.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}